import axios from "axios";
import config from "./configs";

// Token JWT generado previamente (reemplaza este string por tu token real)
const token = localStorage.getItem('token') || '';

const axiosInstance = axios.create({
  baseURL: config.backendUrl, // Establece la URL base
  headers: {
    "Authorization": `Bearer ${token}`
  },
});

export default axiosInstance;
