
import NewPromptCreation from "../../components/newPromptStart/newPromtStart";


const Home = () => {


  return (
    <>
        <div>
          <div style={{height:"70vh",overflowY: "auto"}}>
            <h1 className="text-center">MINING IA</h1>
            <p className="text-center">Escribe tu mensaje para crear un chat</p>
          </div>
          <div className="d-flex justify-content-center">
            <NewPromptCreation userId="12231" />
          </div>
      </div>
    </>
  );
};

export default Home;
