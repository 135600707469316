import { Link , useLocation} from "react-router-dom";
import axiosInstance from "../../configs/axiosInstance";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FaBars, FaPlus } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa"; // Importa el icono correcto

const ChatList = () => {
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  // Estado para controlar si el sidebar está colapsado o no
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axiosInstance.get(
          "api/getChatsByUser/12231"
        );
        setChats(response.data.reverse());
      } catch (err) {
        setError("Error loading chats. Please try again later.");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChats();
  }, [location]);

  // Función para obtener las primeras 5 palabras del chat
  const getChatPreview = (chat) => {
    if (!chat.history || chat.history.length === 0) return "No content available";
    
    // Tomamos el primer mensaje del historial
    const firstMessage = chat.history[0]?.userMessage || "No content available";
    
    return firstMessage.split(" ").slice(0, 5).join(" ") + "...";
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Nuevo efecto para detectar el tamaño de la ventana y colapsar el sidebar en tamaños sm (<576px)
  // useEffect(() => {
  //   const handleResize = () => {
  //     // Si el ancho es menor a 576px, colapsamos el sidebar; en caso contrario, lo expandimos
  //     setIsCollapsed(window.innerWidth >= 600 && window.innerWidth < 700);

  //   };

  //   // Agregamos el listener
  //   window.addEventListener("resize", handleResize);
  //   // Ejecutamos la función al montar para establecer el estado inicial
  //   handleResize();

  //   // Limpiamos el listener al desmontar el componente
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div
      className={` vh-100 d-flex flex-column ${isCollapsed ? "collapsed" : ""}`}
      style={{
        width: isCollapsed ? "70px" : "300px",
        transition: "width 0.3s",
        color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",

      }}
    >

      <div className="d-none d-lg-flex py-3 d-flex justify-content-center ">
        {!isCollapsed && <h2 className="text-center px-4 m-0">MINING IA</h2>}
        <button
          className="btn hover-scale btn-sm"
          onClick={toggleCollapse}
          style={{ border: "none" ,color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}
        >
          <FaBars size={20} />
        </button>
      </div>



      <div className="container my-3">
        
        <div className="d-flex flex-column align-items-center">
          
          {!isCollapsed ? (
            <Link to="/" className=" btn btn-dark mb-4">
              <i className="bi bi-plus-circle me-2"></i>Create New Chat
            </Link>
          ) : (
            <Link to="/" className="btn btn-light mb-4">
              <FaPencilAlt />
            </Link>
          )}

{!isCollapsed &&(<h5 className="text-secondary">Recent Chats</h5>)}
        </div>

        <hr />

        {!isCollapsed &&(
        <div className="list-group overflow-auto" style={{ maxHeight: "70vh" ,        color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}>
          {isLoading ? (
            <div className="text-center py-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center" role="alert">
              <i className="bi bi-exclamation-triangle me-2"></i>
              {error}
            </div>
          ) : chats.length > 0 ? (
            chats.map((chat) => (
              <Link
                to={`chats/${chat.chatId}`}
                key={chat.chatId}
                style={{        color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}
                className="list-group-item list-group-item-action"
              >
                <i className="bi bi-chat-dots me-2"></i>{getChatPreview(chat)}
              </Link>
            ))
          ) : (
            <div className="alert alert-info text-center" role="alert">
              <i className="bi bi-info-circle me-2"></i>No chats available.
            </div>
          )}
        </div>      )}

      </div>


    </div>
  );
};

export default ChatList;
