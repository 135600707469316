import React from "react";
import { FaBars, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import ChatList from "../chatList/chatList";
import ThemeToggle from "../themeButton/themeButton";


const Navbar = () => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    // Optionally, you might want to redirect to login page
    window.location.href = '/login';
  };

  // Add this line to get username from localStorage
  const username = localStorage.getItem('user_name') || 'Usuario';

  return (
    <>
      {/* Navbar */}
      <nav style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}  className="navbar navbar-light ">
        <div className="container-fluid">
          {/* Botón para abrir el offcanvas solo en pantallas pequeñas */}
          <div className="d-block d-lg-none">
            <button
              className="btn btn-light btn-sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#chatSidebar"
              style={{ border: "none" ,color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}
              
            >
              <FaBars size={20} />
            </button>

            <Link style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}  to="/" className="btn btn-light">
              <FaPencilAlt />
            </Link>
          </div>

          {/* Logo */}
          <span style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} className="navbar-brand mb-0 h1">ISPROTEC</span>
          {/* Perfil */}
          <div className="nav-item dropdown user-dropdown" >
            <a className="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown">
              <img className="img-xs border rounded-circle" style={{width:"45px"}} src="/logo192.png" alt="Profile" />
            </a>
            <div style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} className="dropdown-menu dropdown-menu-end navbar-dropdown" data-bs-popper="static">

              <div className="dropdown-header text-center">
                <img className="img-md border rounded-circle" style={{width:"45px"}} src="/logo192.png" alt="Profile" />
                <p style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}}  className="mb-1 mt-3 font-weight-semibold">{username}</p>
              </div>

              <a style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} className="dropdown-item">                <ThemeToggle></ThemeToggle></a>
              <a style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} className="dropdown-item">Mi perfil</a>
              <a style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} className="dropdown-item">Soporte</a>
              <a 
                style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} 
                className="dropdown-item" 
                onClick={handleLogout}
                role="button"
              >
                Cerrar Sesión
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar tipo modal (Offcanvas) */}
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="chatSidebar"
        aria-labelledby="chatSidebarLabel"
        style={{color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)",}} 
      >
        <div className="offcanvas-header">
          <h5 id="chatSidebarLabel">Chats</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ChatList />
        </div>
      </div>
    </>
  );
};

export default Navbar;


