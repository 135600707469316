import { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const PVPThemeProvider = ({ children }) => {
  // Estado del tema, guardado en localStorage para persistencia
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [fullscreen, setFullscreen] = useState(false);

  // Cambiar el tema y guardarlo en localStorage
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  // Aplicar la clase al body cuando cambia el tema
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

    // Función para alternar pantalla completa
    const toggleFullscreen = () => {
      setFullscreen((prev) => !prev);
    };

  return (
    <ThemeContext.Provider    
    value={{ theme, toggleTheme, fullscreen, toggleFullscreen }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
