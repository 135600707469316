import { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../configs/axiosInstance";
import { useChatContext } from "../context/chatContext";
import { FaArrowUp } from "react-icons/fa";
import { ThemeContext } from "../context/themeContext";

let formWidth

if (window.innerWidth < 550){
  formWidth = "90vw"
}
else{
  formWidth = "60vw"
}

const NewPromptCreation = ({ userId }) => {
  const [question, setQuestion] = useState("");
  const navigate = useNavigate();
  const { setChatId, setInitialQuestion , selectedModel, setSelectedModel  } = useChatContext(); // Usamos el contexto
  const {theme} = useContext(ThemeContext)

  const handleCreateChat = async (e) => {
    e.preventDefault();
    if (!question.trim()) return;

    try {
      // Crear el chat en el backend
      const initResponse = await axiosInstance.post("api/initChat", { userId });
      const newChatId = initResponse.data.chatId;

      // Guardamos la pregunta y el chatId en el contexto
      setChatId(newChatId);
      setInitialQuestion(question);

      // Redirigir al chat sin pasar variables en la URL
      navigate(`/chats/${newChatId}`);
    } catch (err) {
      console.error("Error creating chat:", err);
    }
  };

  // Función para manejar el cambio de modelo
  const toggleModel = (e) => {
      e.preventDefault();
      setSelectedModel((prevModel) => (prevModel === "ask" ? "agent" : "ask"));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleCreateChat(e);
    }
  };

// Para el botón de enviar:
const sendButtonClass = theme === "dark" 
  ? "btn btn-light text-dark" 
  : " btn btn-dark text-white";

// Para el botón GDMX1, considerando también la lógica de selectedModel:
const gdmx1ButtonClass = selectedModel === "agent"
  ? (theme === "dark" ? "btn-light text-dark" : "btn-dark text-light")
  : (theme === "dark" ? " btn-dark text-white" : "btn-light text-dark");

  const shadowOpacity = theme === "dark" ? "0.5" : "0.1"

  return (
    <form style={{borderRadius:"10px", boxShadow: `0 0 10px 0 rgba(0, 0, 0, ${shadowOpacity})`, width: formWidth, color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)" }} onSubmit={handleCreateChat} className="mx-md-5 d-flex flex-column  p-3 rounded ">
      <div className="d-flex  flex-column align-items-start position-relative w-100">
        {/* Input de Mensaje */}
        <div className="w-100 d-flex align-items-center">
          <input
          style={{  color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)" }} 
            type="text"
            placeholder="Envía un mensaje"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onKeyDown={handleKeyDown}
            className="form-control rounded-pill px-4 py-2 flex-grow-1"
          />


        </div>

   
        <div style={{width:"100%"}} className="d-flex align-items-center justify-content-between">
          {/* Botón GDMX1 - Toggle entre "ask" y "agent" */}
          <button
            type="button"
            className={`btn ${gdmx1ButtonClass} rounded mt-2 px-4 py-2 fw-bold text-center`}
            onClick={toggleModel}
          >
            GDMX1
          </button>

          {/* Botón de Enviar */}
          <button
            className={` ${sendButtonClass}  mt-2 `}
            type="submit"

            style={{borderRadius:"50%",width:"40px",height:"40px"}}
          >
            <FaArrowUp />
          </button>
        </div>
      </div>

    </form>
  );
};

export default NewPromptCreation;
