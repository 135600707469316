import React from "react";
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ChatList from "../components/chatList/chatList";
import Navbar from "../components/navbar/navbar";
import { ChatProvider } from "../components/context/chatContext";

const RootLayout = () => {

  return (
    <div style={{color: "var(--text-color)" , backgroundColor:"var(--bg-color)" } } className=" rootLayout vh-100 d-flex">
      {/* Sidebar: solo se muestra si el rol es "boss" */}

        <div className="sidebar d-none d-lg-block ">
          <ChatList></ChatList>
        </div>
   
      {/* Right Content */}
        <div className="d-flex flex-column flex-grow-1">
        {/* Navbar */}
          <Navbar></Navbar>

            {/* Main Content */}
          <main style={{color: "var(--text-color)" , backgroundColor:"var(--bg-color)" } } className=" pt-4 ">
            <ChatProvider>
              <Outlet />
            </ChatProvider>
          </main>
        </div>
    </div>
  );
};

export default RootLayout;