import { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../configs/axiosInstance";
import NewPrompt from "../../components/newPromt/newPromt";
import { useChatContext } from "../../components/context/chatContext";
import ReactMarkdown from "react-markdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./chat.css";

const ChatPage = () => {
  const { id: chatId } = useParams();
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoadingHist, setIsLoadingHist] = useState(true);
  const [error, setError] = useState(null);
  const userId = "12231";
  const { initialQuestion ,isLoading, selectedModel, calledModel} = useChatContext();
  const [isNewChat, setIsNewChat] = useState(true);
  const chatContainerRef = useRef(null);


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await axiosInstance.get("api/getChatsByUser/12231");
        const chat = response.data.find((c) => c.chatId === chatId);
        if (!chat) throw new Error("Chat not found");

        let history = chat.history || [];
        const isNewChat = history.length === 0;
        
        if (initialQuestion && !history.some(msg => msg.userMessage === initialQuestion)) {
          history = [{ userMessage: initialQuestion, aiResponse: "" }, ...history];
        }

        setChatHistory(history);
        setIsNewChat(isNewChat);
      } catch (err) {
        setError("Error loading chat.");
        console.error(err);
      } finally {
        setIsLoadingHist(false);
      }
    };

    fetchChat();
  }, [chatId, initialQuestion]);

  const addChatEntry = (newEntry) => {
    setChatHistory((prev) => [...prev, newEntry]);
  };

  const updateLastChatEntry = (updatedEntry) => {
    setChatHistory((prev) => {
      const newHistory = [...prev];
      newHistory[newHistory.length - 1] = updatedEntry;
      return newHistory;
    });
  };

  useEffect(() => {
console.log("chatHistory", chatHistory)
  }, [chatHistory])

  const parseMessageSegments = (message) => {
    const regex = /\[\s*(Tool|AI)\s*\]\s*([\s\S]*?)(?=\[\s*(Tool|AI)\s*\]|$)/g;
    const segments = [];
    let match;
    
    while ((match = regex.exec(message)) !== null) {
      segments.push({
        type: match[1],
        content: match[2].trim()
      });
    }

    if (segments.length === 0) {
      segments.push({
        type: "AI",
        content: message.trim()
      });
    }


    // Solo para el modelo agent, mantener solo el último mensaje AI
    // si es el último mensaje del chat (el que está en proceso)
    if (calledModel === "agent") {
      const isLastMessage = chatHistory.length > 0 && 
        chatHistory[chatHistory.length - 1].aiResponse === message;

      if (isLastMessage) {
        let lastAIIndex = -1;
        for (let i = 0; i < segments.length; i++) {
          if (segments[i].type === "AI") {
            lastAIIndex = i;
          }
        }
        if (isLoading) {
          // During loading of last message, only return Tool segments
          return segments.filter(seg => seg.type === "Tool");
        }
        
        return segments.filter((seg, index) => seg.type !== "AI" || index === lastAIIndex);
      }
    }

    // Para mensajes anteriores o otros modelos, devolver todos los segmentos
    return segments;
  };

  const showInitialQuestionLoading = initialQuestion && chatHistory.length === 0;

  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1 className="ms-4">Chat </h1>

      <div className="p-5 mx-0 mx-md-4" ref={chatContainerRef} style={{ height: "60vh", overflowY: "auto" }}>
        
        { showInitialQuestionLoading && (
          <div className="mx-0 mx-md-4 px-0 px-md-5" style={{ marginBottom: "1rem" }}>

                <div className="spinner-border spinner-border-sm me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
      

          </div>
        )}

        {chatHistory.map((message, index) => {
          const segments = parseMessageSegments(message.aiResponse);
          const toolMessages = [...new Set(segments.filter(seg => seg.type === "Tool").map(seg => seg.content))]; // Evita duplicados
          console.log("segments", segments)
          const aiMessage = segments.find(seg => seg.type === "AI");

          return (
            <div className="mx-0 mx-md-4 px-0 px-md-5" key={index} style={{ marginBottom: "1rem" }}>
              {message.userMessage && (
                <div className="d-flex justify-content-end mb-3">
                  <div 
                    style={{
                      maxWidth: '70%',
                      backgroundColor: '#007bff',
                      color: 'white',
                      padding: '10px 15px',
                      borderRadius: '15px 15px 0 15px',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                    }}
                  >
                    <p className="m-0">
                      {message.userMessage}
                    </p>
                  </div>
                </div>
              )}

              {toolMessages.length > 0 && (
                <div className="d-flex justify-content-start mb-3">
                  <div style={{ maxWidth: '70%' }}>
                    <div className="dropdown mb-2">
                      <div
                        className="dropdown-toggle p-2"
                        style={{
                          backgroundColor: "#f0f0f0",
                          borderLeft: "3px solid #007BFF",
                          padding: "0.5rem",
                          cursor: "pointer",
                          animation: isLoading ? "blinking 1s infinite alternate" : "none"
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <strong className="text-dark">🔄 Analizando datos GDMX1...</strong>
                      </div>
                      <ul className="dropdown-menu p-2" style={{ width: "100%" }}>
                        {toolMessages.map((msg, i) => (
                          <li key={i} style={{ marginBottom: "0.5rem" }}>
                            <ToolMessage content={msg} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {aiMessage && (
                <div className="d-flex justify-content-start mb-3">
                  <div 
                    style={{
                      maxWidth: '70%',
                      backgroundColor: '#f0f0f0',
                      padding: '10px 15px',
                      borderRadius: '15px 15px 15px 0',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                      color: "black"
                    }}
                  >
                    <ReactMarkdown>{aiMessage.content}</ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="d-flex justify-content-center">
        <NewPrompt
          userId={userId}
          chatId={chatId}
          chatHistory={chatHistory}
          addChatEntry={addChatEntry}
          updateLastChatEntry={updateLastChatEntry}
          initialQuestion={initialQuestion}
          isNewChat={isNewChat}
        />
      </div>


    </div>
  );
};

const ToolMessage = ({ content }) => {
  const [expanded, setExpanded] = useState(false);
  const previewLimit = 50; // Cantidad de caracteres antes de mostrar "Ver más"

  return (
    <div>
      <p style={{ display: "inline" }}>
        <ReactMarkdown>
          {expanded ? content : `${content.slice(0, previewLimit)}${content.length > previewLimit ? "..." : ""}`}
        </ReactMarkdown>
      </p>
      {content.length > previewLimit && (
        <button
          onClick={() => setExpanded(!expanded)}
          className="btn btn-link p-0"
          style={{ fontSize: "0.9rem" }}
        >
          {expanded ? "Ver menos" : "Ver más"}
        </button>
      )}
    </div>
  );
};

export default ChatPage;
