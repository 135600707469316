import { useState, useEffect } from "react";

const StreamingChat = ({ chatId, userId, userQuestion }) => {
    console.log("Enviando datos:", JSON.stringify({ userId, chatId, message: userQuestion }));

  const [response, setResponse] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);

  useEffect(() => {
    if (!userQuestion) return;

    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchStream() {
      setIsStreaming(true); // Indicar que el streaming está activo
      setResponse(""); // Limpiar la respuesta previa

      try {
        const res = await fetch("http://localhost:5000/api/ask", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId,chatId, message: userQuestion }),
          signal: signal, // Pasamos la señal al fetch
        });

        if (!res.ok) {
          throw new Error(`Error en la solicitud: ${res.status} ${res.statusText}`);
        }

        if (!res.body) throw new Error("No response body");

        const reader = res.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          setResponse((prev) => prev + decoder.decode(value, { stream: true }));
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Stream abortado por desmontaje del componente.");
        } else {
          console.error("Error en el stream:", err);
        }
      } finally {
        setIsStreaming(false); // Indicar que terminó el streaming
      }
    }

    fetchStream();

    return () => {
      console.log("Cerrando el stream...");
      controller.abort(); // Cancelamos la solicitud solo si el componente se desmonta
    };
  }, [chatId, userId, userQuestion]);

  return (
    <div>
      <h2>Respuesta en tiempo real:</h2>
      <pre>{response}</pre>
      {isStreaming && <p>🔄 Generando respuesta...</p>}
    </div>
  );
};

export default StreamingChat;
