import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from './layouts/rootLayout';
import Home from './pages/home/home';
import Chat from './pages/chat/chat';
import 'bootstrap/dist/css/bootstrap.min.css';
import StreamingChat from './components/test';
import { PVPThemeProvider } from './components/context/themeContext';
import Login from './pages/login/login';
import ProtectedRoute from './components/ProtectedRoute';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout/>,
    children: [
      {
        index: true,
        element: <ProtectedRoute><Home/></ProtectedRoute>
      },
      {
        path: "chats/:id",
        element: <ProtectedRoute><Chat/></ProtectedRoute>
      },
      {
        path: "test",
        element: <ProtectedRoute>
          <StreamingChat 
            chatId="67a0d0edb3b3aa4da59cbf03" 
            userId="12231" 
            userQuestion="¿Qué es el GDMX1?"
          />
        </ProtectedRoute>
      },
    ]
  },
  {
    path: "login",
    element: <Login/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PVPThemeProvider>
      <RouterProvider router={router} />
    </PVPThemeProvider>
  </React.StrictMode>
);



