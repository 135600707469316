import { createContext, useContext, useState } from "react";

// Crear el contexto
const ChatContext = createContext();

// Hook personalizado para acceder al contexto fácilmente
export const useChatContext = () => useContext(ChatContext);

// Proveedor del contexto
export const ChatProvider = ({ children }) => {
  const [chatId, setChatId] = useState(null);
  const [initialQuestion, setInitialQuestion] = useState("");
  const [selectedModel, setSelectedModel] = useState("ask"); // Nueva variable de estado para el modelo seleccionado
  const [isLoading, setIsLoading] = useState(false);
  const [calledModel,setCalledModel] = useState("ask")


  return (
    <ChatContext.Provider value={{ chatId, setChatId, initialQuestion, setInitialQuestion, selectedModel, setSelectedModel ,isLoading , setIsLoading, calledModel ,setCalledModel}}>
      {children}
    </ChatContext.Provider>
  );
};
