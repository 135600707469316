import { useEffect, useState, useRef ,useCallback,useContext} from "react";

import { useChatContext } from "../context/chatContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./newPromt.css";
import { FaArrowUp } from "react-icons/fa";
import config from "../../configs/configs";
import { ThemeContext } from "../context/themeContext";

let formWidth

if (window.innerWidth < 550){
  formWidth = "90vw"
}
else{
  formWidth = "60vw"
}


const NewPrompt = ({
  userId,
  chatId,
  chatHistory,
  addChatEntry,
  updateLastChatEntry,
  initialQuestion,
  isNewChat
}) => {

  // Inicialmente, el input toma el valor del mensaje inicial (si existe)
  const [question, setQuestion] = useState(initialQuestion || "");

  // const [selectedModel, setSelectedModel] = useState("ask");
  const { selectedModel, setSelectedModel,isLoading, setIsLoading, setCalledModel } = useChatContext();
  const endRef = useRef(null);
  const [initialSent, setInitialSent] = useState(false);
  const didMountRef = useRef(false);
  const {theme} = useContext(ThemeContext)

  // Desplazar el scroll al final cuando el historial se actualiza
  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  // Función para enviar un mensaje (se usará tanto en el envío manual como en el envío automático del initialQuestion)
  const handleSendMessage =  useCallback(  async (inputMessage) => {
    const message = typeof inputMessage === "string" ? inputMessage : question;
    if (!message.trim()) return;
  
    setIsLoading(true);
    let currentChatId = chatId;
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('No token found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}api/${selectedModel}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" ,
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ userId, chatId: currentChatId, message }),
      });
  
      if (!response.body) throw new Error("No stream response received.");
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let aiResponse = "";
  
      // Si es un mensaje nuevo (por ejemplo, el del usuario) se agrega la entrada.
      // Ojo: si ya se había enviado el mensaje inicial, no queremos duplicarlo.
      // Aquí asumimos que 'addChatEntry' se usa solo cuando no existe una entrada previa para este mensaje.
      const newEntry = { userMessage: message, aiResponse: "" };
      addChatEntry(newEntry);
  
      // Se procesa el stream y se actualiza el mismo objeto (la última entrada del chat)
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
  
        // Decodificamos el fragmento recibido
        const textChunk = decoder.decode(value, { stream: true });

        // console.log("text chunk",textChunk)

        // Limpiamos cada línea: si empieza con "data:" se elimina ese prefijo
        // const cleanedChunk = textChunk
        // .split("\n")
        // .map((line) =>
        //   line.startsWith("data:") ? line.replace(/^data:\s*/, "") : line
        // )
        // .join("");
      
      
        aiResponse += textChunk;
        newEntry.aiResponse = aiResponse;

        // console.log('new entry',newEntry)

        // Actualizamos la última entrada para que se muestre de forma acumulativa
        updateLastChatEntry({ ...newEntry });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
      setQuestion("");
    }
  }, [question, chatId, selectedModel, userId, addChatEntry, updateLastChatEntry]);
  
  // Manejador del submit del formulario para envío manual
  const handleSubmit = (e) => {
    e.preventDefault();
    setCalledModel(selectedModel)
    handleSendMessage();
  };

    // Función para manejar el cambio de modelo
    const toggleModel = () => {
      setSelectedModel((prevModel) => (prevModel === "ask" ? "agent" : "ask"));
  };

  // Enviar el mensaje inicial (del contexto) cuando se monta el componente
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      if (initialQuestion && !initialSent && isNewChat) {
        handleSendMessage(initialQuestion)
        setInitialSent(true);;
    } 
  }
    // NOTA: asegúrate de que el initialQuestion se procese solo una vez
    // o implementa una lógica para evitar duplicados.
  }, [handleSendMessage, initialQuestion, initialSent]);


// Para el botón de enviar:
const sendButtonClass = theme === "dark" 
  ? "btn btn-light text-dark" 
  : " btn btn-dark text-white";

// Para el botón GDMX1, considerando también la lógica de selectedModel:
const gdmx1ButtonClass = selectedModel === "agent"
  ? (theme === "dark" ? "btn-light text-dark" : "btn-dark text-light")
  : (theme === "dark" ? " btn-dark text-white" : "btn-light text-dark");

const shadowOpacity = theme === "dark" ? "0.5" : "0.1"

  return (
    <form
      style={{ borderRadius:"10px", boxShadow: `0 0 10px 0 rgba(0, 0, 0, ${shadowOpacity})`, width: formWidth, color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)"  }}
      onSubmit={handleSubmit}
      className="mx-md-5 d-flex flex-column  p-3  "
    >
      <div className="d-flex  flex-column align-items-start position-relative w-100">
        {/* Input de Mensaje */}
        <input
          style={{ width: "100%", color:"var(--text-color)",backgroundColor:"var(--sidebar-bg)"  }}
          type="text"
          placeholder="Envía un mensaje"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
            className="form-control rounded-pill px-4 py-2 flex-grow-1"
          disabled={isLoading}
        />




        <div style={{width:"100%"}} className="d-flex align-items-center justify-content-between"> 

          {/* Botón de Cambio de Modelo - Agregar type="button" */}
          <button
            type="button"
            className={`btn ${gdmx1ButtonClass} rounded mt-2 px-4 py-2 fw-bold text-center`}
            onClick={toggleModel}
          >
            GDMX1
          </button>

          {/* Botón de Enviar */}
          <button
            style={{borderRadius:"50%",width:"40px",height:"40px"}}
            className={` ${sendButtonClass}  mt-2 `}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 
              <div className="loading-indicator d-flex align-items-center mt-2">
                <div className=" text-light " role="status"></div>
              </div> 
              : <FaArrowUp />}
          </button>
        </div>
      </div>



      <div ref={endRef}></div>
    </form>
  );
};

export default NewPrompt;

